<template>
  <div>
    <b-sidebar
      id="sidebar-1"
      aria-labelledby="sidebar-no-header-title"
      no-header
      bg-variant="dark"
      text-variant="light"
      shadow
      backdrop-variant="dark"
      backdrop
    >
      <template #default="{ hide }">
        <div class="p-3">
          <h5 id="sidebar-no-header-title">Welcome, {{ firstName }}</h5>
          <hr class="mb-5" />
          <nav class="mb-3">
            <b-nav vertical>
              <div v-for="item in menuItems" :key="item.id">
                <b-nav-item @click="hide"
                  ><router-link class="white-link" :to="item.route">{{
                    item.name
                  }}</router-link></b-nav-item
                >
              </div>
            </b-nav>
          </nav>
          <hr />
          <b-button class="close-menu-margin" variant="primary" @click="hide"
            >Close menu</b-button
          >
          <br /><br />
          <span v-if="isMobile" class="copyright close-menu-margin">
            Get Nisharga App now:
          </span>
          <br v-else />
          <a
            v-if="isMobile"
            href="https://play.google.com/store/apps/details?id=com.nisharga.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            ><img
              class="google-play-icon"
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" /></a
          ><br />
          <span class="copyright close-menu-margin"
            >Contact us:
            <a style="color: white" href="mailto:nisharga.info@gmail.com"
              ><b>nisharga.info@gmail.com</b></a
            ></span
          ><br />
          <span class="copyright close-menu-margin"
            >© 2023 all rights reserved</span
          >
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BSidebar, BNav, BNavItem, BButton } from "bootstrap-vue";

export default {
  components: {
    BSidebar,
    BNav,
    BNavItem,
    BButton,
  },

  data() {
    return {
      menuItems: [
        {
          id: 1,
          name: "Home",
          route: "/home",
        },
        {
          id: 2,
          name: "Write",
          route: "/mypost",
        },
        {
          id: 3,
          name: "Bookmarked",
          route: "/savedpost",
        },
        {
          id: 6,
          name: "Notifications",
          route: "/notifications",
        },
        {
          id: 7,
          name: "My Creativity",
          route: "/mydairy",
        },
        {
          id: 8,
          name: "Drafts",
          route: "/mydrafts",
        },
        {
          id: 9,
          name: "Favuorite Authors",
          route: "/favorite-writers",
        },
      ],
      isMobile: false,
    };
  },

  computed: {
    ...mapGetters(["userDetails"]),

    firstName() {
      let nameArr = this.userDetails?.name?.split(" ") ?? [];
      return nameArr[0];
    },
  },

  mounted() {
    if (window.screen.width < 768) {
      this.menuItems.splice(
        3,
        0,
        {
          id: 4,
          name: "Trending Post",
          route: "/trendingPost",
        },
        {
          id: 5,
          name: "Trending Authors",
          route: "/trendingAuthors",
        }
      );
    }

    this.isMobile = window.screen.width < 1024;

    this.menuItems.push({
      id: 10,
      name: "Profile",
      route: `/profile/myprofile`,
    });
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #ffffff;

  &:hover {
    color: #17a2b8;
  }
}
hr {
  border-top: 1px solid rgb(255 255 255 / 100%);
}
.copyright {
  font-size: 12px;
}
.close-menu-margin {
  margin-left: 24px;
}
.google-play-icon {
  margin-left: 24px;
  max-width: 150px;
}
</style>
